
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@media print
{
	.no-print, .no-print *
	{
		display: none !important;
	}
}

.pr-0{
	padding-right:0px;
}

.pl-0{
	padding-left:0px;
}

.logo {
	max-height: 18px;
}

.job-img-index{
	padding-top:100%;
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center center;
}

.form-horizontal{
	.form-group{
		margin-left:0px;
		margin-right:0px;
	}
}

.header-navbar-transparent.header-navbar-fixed.header-navbar-scroll #header-navbar,
.header-navbar-transparent #header-navbar {
	background: #fff;
	//padding-bottom:20px;
}

.nav-main-header a, .nav-main-header a:hover, .nav-main-header a:focus, .nav-main-header a.active {
	color: #3097D1;
}

.text-white {
	color: #fff !important;
}

$marginedColumnValue : 20px;

.margined-column{
	margin-bottom:$marginedColumnValue;
	overflow:auto;
}

.margined-column-half{
	margin-bottom:$marginedColumnValue/2;
	overflow:auto;
}

.inline-small-block{
	max-width:200px;
	display:inline-block;
	width:100%;
	margin-left:10px !important;
	margin-right:10px !important;
}

.user-profile-show-container{
	.row.block{
		padding-top:20px;
		margin-left:0px;
		margin-right:0px;
	}
}

.bg-light-gray{
	background-color:#f5f5f5;
}

.social-share-dropdown{
	min-width:200px;
	text-align:center;

	.dropdown-menu{

		min-width:200px;
		text-align:center;

		&>li>a{
			display:inline-block;
			font-size:17px;
			color:$brand-primary;
		}
	}
}

.pl-0-small-15{
	padding-left:0px;
	@media screen and (max-width:767px){
		padding-left:15px;
	}
}

.mb-10-small-20{
	margin-bottom:10px;
	@media screen and (max-width:767px){
		margin-bottom:20px;
	}
}

.text-left-xs{
	@media screen and (max-width:767px){
		text-align:left;
	}
}

.mb20-xs{
	@media screen and (max-width:767px){
		margin-bottom:20px;
	}
}

.imagient-link{
	color:#000000;

	&:hover{
		color: #CF5800;
	}
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
	font-size: 1.25em;
	display: inline-block;
	width: 220px;
	height: 220px;
	text-align: center;
	border-radius: 999999px;
	border: 1px solid #70b9eb;
	background-color: #FFFFFF;
	color: #646464;
	//padding: 10px;
	word-break: break-word;
	padding: 0px;
	overflow: hidden;

	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.inputfile + label .image-container-upload{
	//width:130px;
	//height:130px;
	display:inline-block;
	//background-size: contain;
	//background-repeat: no-repeat;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 220px;
    height: 220px;
}

.inputfile:focus + label,
.inputfile + label:hover {
	background-color: #EEEEEE;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
	pointer-events: none;
}

@media print
{
	.table.table-vcenter.table-striped
	{
		th,tr,td {
			font-size: 12px !important;
		}
	}
}

/*
.row.jobs-container{
	display: flex;
	flex-wrap: wrap;
}

@media screen and (max-width:767px){
	.row.jobs-container{
		display:block;
	}
}
*/

#page-footer {
  .content-boxed.text-white {
    padding: 60px 30px;
    p {
      margin-bottom: 0;
    }
  }
}

.dashboard-boxes {
  h1, h2, h3, h4, h5 {
    font-family: 'Titillium Web', sans-serif;
  }
}

.block-bordered.ribbon {
  border-color: #304c77;
}

.cookie-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .content {
    width: 80%;
    max-height: 80%;
    overflow: auto;
    padding: 2rem;
    background: #fff;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,.5);
  }
}